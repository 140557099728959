$(document).ready(function ()
{
	// Youtube/Vimeo thumbs
	$("[class*='rc-video'] .thumb").click( startVideo );

	function startVideo(e) {
		var block = $(e.currentTarget);
		var iframe = $('iframe', block.parent());
		var src = iframe.attr('src');

		if (src.indexOf('autoplay=0') > -1) {
			iframe.attr('src', src.replace('autoplay=0','autoplay=1') );
		} else {
			iframe.attr('src', src + '?autoplay=1');
		}
		block.fadeOut(150);
	}


	// Lightbox - UNCOMMENT ME BIJ KOPPELEN

	$('a.lightbox').fancybox({
		overlayOpacity: 0.8,
		overlayColor: 'black',
		padding: 0,
		titleShow: true
	});


	/*
	 *	SLIDER
	 */
	
	$('.project-slider').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		fade: false,
		asNavFor: '.project-slider-thumbs',
		prevArrow: '<button class="slick-prev"><i class="icon-angle-left"></i></button>',
		nextArrow: '<button class="slick-next"><i class="icon-angle-right"></i></button>'
	});

	$('.project-slider-thumbs').slick({
		slidesToShow: 4,
		slidesToScroll: 1,
		arrows: false,
		asNavFor: '.project-slider',
		prevArrow: '<button class="slick-prev"><i class="icon-angle-left"></i></button>',
		nextArrow: '<button class="slick-next"><i class="icon-angle-right"></i></button>',
		focusOnSelect: true,
		centerMode: true,
		centerPadding: 0
	});
	
});


$(window).load(function(){
	/*
	 *	SCROLL REVEAL
	 */

	window.sr = ScrollReveal({ scale: 1, duration: 350 });

	sr.reveal('.sr-top', {origin: 'top'}, 50);
	sr.reveal('.sr-left', {origin: 'left'}, 50);
	sr.reveal('.sr-right', {origin: 'right'}, 50);
    sr.reveal('.sr-bottom', {origin: 'bottom'}, 50);
    

    
    // Popup
    if ($('#popup').length > 0) {
        $.fancybox({
            type: 'inline',
            href: '#popup',
            overlayOpacity: 0.8,
            overlayColor: 'black',
        });
    }
});
